import React from 'react'
import './Login.css'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { Button, Typography } from '@material-ui/core'
import { useLocation } from 'react-router'
import OauthLogin from './OauthLogin'

export const GET_CLIENT_ID = gql`
query GetClientId {
  clientId
}
`

const allScopes = [
    "truckinspect.delete-inspections",
    "truckinspect.manage-questions",
    "truckinspect.manage-schedule",
    "truckinspect.perform-inspection",
    "truckinspect.schedule-inspections",
    "truckinspect.view-all-inspections"
]

export default function Login({ setToken }: { setToken: (token: string, scope: string[]) => void }) {
    const { data } = useQuery(GET_CLIENT_ID)
    const location = useLocation()

    const redirectUrl = new URL("/oauthlogin", window.location.origin)
    const oauthUrl = new URL("https://sso.a-qualitypools.net/oauth/authorize")
    oauthUrl.searchParams.append("response_type", "code")
    oauthUrl.searchParams.append("client_id", data?.clientId)
    oauthUrl.searchParams.append("scope", allScopes.join(' '))
    oauthUrl.searchParams.append("redirect_uri", redirectUrl.toString())

    if (location.pathname === "/oauthlogin") {
        return (
            <OauthLogin setToken={setToken} />
        )
    }

    return (
        <div className="login-page">
            <img src="/img/logo.png" alt="" />
            <Typography variant="h2" align="center">Truck Inspections</Typography>
            <Button size="large" variant="contained" color="primary" component="a" href={oauthUrl.toString()} disabled={data == null}>Login</Button>
        </div>
    )
}