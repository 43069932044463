import React from 'react'
import { Table, TableContainer, Paper, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import { UserData } from './Users'
import { Link } from 'react-router-dom'

export default function UsersTable({ users }: { users: UserData[] }) {

    return (
        <TableContainer component={Paper}>
            <Table className="users-table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).map((user) => (
                        <TableRow key={user.id}>
                            <TableCell component="th" scope="row" align="left">
                                <Link to={`/user/${user.id}`} style={{ textDecoration: "unset", color: "unset" }}>{user.name}</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}