import React from 'react'
import './AddDialog.css'
import { Dialog, DialogTitle, FormControl, InputLabel, Select, MenuItem, DialogContent, Button, DialogContentText, DialogActions, useMediaQuery, useTheme } from '@material-ui/core'
import { UserData } from '../users/Users'

interface EditAddState {
    open: boolean
    userId: string
}

export default function AddDialog({ users, day, week, editAdd, setEditAdd, add }: {
    users: UserData[]
    day: string,
    week: number
    editAdd: EditAddState,
    setEditAdd: (state: EditAddState) => void,
    add: () => void
}) {

    const theme = useTheme()
    const editFullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const closeEditAdd = () => {
        setEditAdd({
            open: false,
            userId: ""
        })
    }

    const setAddUser = (userId: string) => {
        setEditAdd({
            open: editAdd.open,
            userId: userId
        })
    }

    return (
        <Dialog onClose={closeEditAdd} open={editAdd.open} fullScreen={editFullScreen}>
            <DialogTitle >Add Recurring Inspection</DialogTitle>
            <DialogContent className="add-recurring-dialog">
                <DialogContentText className="day-label">For week {week} {day}s</DialogContentText>
                <FormControl className="user-select-control">
                    <InputLabel className="select-label">User</InputLabel>
                    <Select
                        className="user-select"
                        value={editAdd.userId}
                        onChange={(e) => setAddUser(e.target.value as string)}
                        variant="outlined"
                        label="User"
                    >
                        <MenuItem value={"none"} disabled>None</MenuItem>
                        {users.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).map((user) => (
                            <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button color="primary" onClick={closeEditAdd}>Cancel</Button>
                    <Button color="primary" onClick={add}>Add</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}