import React, { useState } from 'react'
import { Paper, IconButton, Switch } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import Editable from '../../components/Editable';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_QUESTION } from "./Questions"

export interface QuestionData {
    id: string,
    index: number,
    questionText: string,
    shortName: string,
    critical: boolean
    lastCompleted: number
}

export default function Question({ question, onDelete, moveQuestion, editActive, setEditActive }:
    {
        question: QuestionData,
        onDelete: () => void,
        moveQuestion: (newIndex: number) => void,
        editActive: boolean,
        setEditActive: (valie: boolean) => void
    }) {
    const [editText, setEditText] = useState(question.questionText)
    const [editShortname, setEditShortname] = useState(question.shortName)
    const [editCritical, setEditCritical] = useState(question.critical)
    const [updateQuestion] = useMutation(UPDATE_QUESTION)

    const edit = () => {
        setEditText(question.questionText)
        setEditShortname(question.shortName)
        setEditCritical(question.critical)
        setEditActive(true)
    }

    const onEditTextChange = (text: string) => {
        setEditText(text)
    }

    const onEditShortnameChange = (text: string) => {
        setEditShortname(text)
    }

    const submitEdit = () => {
        updateQuestion({
            variables: { id: question.id, text: editText, shortName: editShortname, critical: editCritical },
            optimisticResponse: {
                "updateQuestion": {
                    "status": "SUCCESSFUL",
                    "question": {
                        "id": question.id,
                        "questionText": editText,
                        "shortName": editShortname,
                        "critical": editCritical,
                        "index": question.index,
                        "__typename": "Question"
                    },
                    "__typename": "UpdateQuestionPayload"
                }
            }
        })

        setEditActive(false)
    }

    const critical = editActive ? editCritical : question.critical

    return (
        <Paper className="question-card" elevation={4}>
            <Editable
                textfieldClassName="question-editable"
                textClassName="question-text"
                label="Question text"
                multiline
                editActive={editActive}
                absoluteValue={question.questionText}
                editableValue={editText}
                onChange={onEditTextChange}
            />
            <Editable
                textfieldClassName="question-shortname-edit"
                textClassName="question-shortname"
                label="Short name"
                editActive={editActive}
                absoluteValue={question.shortName}
                editableValue={editShortname}
                onChange={onEditShortnameChange}
            />
            <div className="question-right">
                <div>
                    {critical ? "Critical" : "Not Critical"}
                    <Switch
                        disabled={!editActive}
                        checked={critical}
                        onChange={() => setEditCritical(!editCritical)}
                        name="checkedB"
                        color="primary"
                    />
                </div>
                {editActive === false ? (
                    <IconButton onClick={edit}>
                        <EditIcon />
                    </IconButton>
                ) : (
                        <IconButton onClick={submitEdit}>
                            <CheckIcon />
                        </IconButton>
                    )}

                {editActive === true ? (
                    <IconButton onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                ) : (
                        <div className="question-actions-move">
                            <IconButton onClick={() => moveQuestion(question.index - (question.index % 2 === 0 ? 3 : 2))}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => moveQuestion(question.index + (question.index % 2 === 0 ? 3 : 2))}>
                                <ArrowDownwardIcon />
                            </IconButton>
                        </div>
                    )}
            </div>
        </Paper >
    )
}