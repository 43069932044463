import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import { InspectionsGraph, InspectionEdge, InspectionData } from "./InspectionsList"
import { GetUsersData, GET_USERS, UserData } from "../users/Users"
import { gql } from "apollo-boost"
import InspectionActions from "./InspectionActions"
import { LinearProgress, ButtonGroup, Button, Paper } from "@material-ui/core"
import { Calendar, momentLocalizer, ToolbarProps, EventWrapperProps, NavigateAction, View } from 'react-big-calendar'
import moment from 'moment-timezone'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { LinkProps, Link } from "react-router-dom"
import { GET_RECURRING_INSPECTIONS, RecurringInspectionData } from "../schedule/Schedule"
import { getInspectionClasses } from "./Inspection"

export const GET_INSPECTIONS_CALENDAR = gql`
query GetInspections($count: Int!, $cursor: String, $after: Long, $before: Long, $filter: InspectionStatusFilter) {
    inspections(count: $count, after: $cursor, afterTimestamp: $after, beforeTimestamp: $before, statusFilter: $filter) {
        edges {
            cursor
            node {
                id
                category
                scheduledFor
                supervisorStatus {
                    submittedAt
                }
                selfStatus {
                    submittedAt
                }
                forUser {
                    id
                    name
                }
            }
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
    }
}
`

export default function InspectionsCalendar() {

    const localizer = momentLocalizer(moment)

    const [activeDate, setActiveDate] = useState(moment().toDate())

    const navigate = (date: Date) => {
        setActiveDate(date)
    }

    const fetchVars: any = { count: 50, after: moment(activeDate).startOf("month").unix(), before: moment(activeDate).endOf("month").unix() }
    const { loading: inspectionsLoading, data, refetch, fetchMore } = useQuery<InspectionsGraph>(GET_INSPECTIONS_CALENDAR, {
        variables: fetchVars,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    })

    const { loading: scheduleInspectionsLoading, data: scheduleInspectionsData } = useQuery<{ recurringInspections: RecurringInspectionData[] }>(GET_RECURRING_INSPECTIONS)


    const { loading: usersLoading, data: usersData, startPolling: usersStartPolling, stopPolling: usersStopPolling } = useQuery<GetUsersData>(GET_USERS)

    useEffect(() => {
        usersStartPolling(5000)
        return usersStopPolling
    })

    function mergeArrays(array1: InspectionEdge[], array2: InspectionEdge[]) {
        let ids = new Set(array1.map(d => d.node.id));
        return [...array1, ...array2.filter(d => !ids.has(d.node.id))];
    }

    if (data != null && inspectionsLoading === false && usersLoading === false) {
        if (data.inspections.pageInfo.hasNextPage === true) {
            fetchMore({
                variables: {
                    cursor: data!!.inspections.pageInfo.endCursor
                },
                updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult!!.inspections.edges;
                    const pageInfo = fetchMoreResult!!.inspections.pageInfo;

                    return {
                        inspections: {
                            __typename: (previousQueryResult.inspections as any).__typename,
                            edges: mergeArrays(previousQueryResult.inspections.edges, newEdges),
                            pageInfo
                        }
                    }
                }
            })
        }
    }

    const date = (e: InspectionData) => {
        return moment.unix(e.scheduledFor).utc().tz("America/New_York", true).toDate()
    }

    let inspectionEvents: InspectionData[] = []
    if (data != null) {
        inspectionEvents = data!!.inspections.edges.map((e) => e.node)
    }
    if (scheduleInspectionsData != null) {
        const daysInMonth = moment(activeDate).daysInMonth()
        for (let i = 1; i <= daysInMonth; i++) {
            const day = moment(activeDate).set('date', i).set('hour', 5).set('minute', 0).set('second', 0).tz("America/New_York", true)
            if (day.isBefore(moment())) { continue }
            const weekCycle = (day.week() % 2) + 1
            for (const scheduledInspection of scheduleInspectionsData.recurringInspections.filter((e) => e.scheduledDay === (day.format('dddd').toUpperCase() + weekCycle.toString()))) {
                inspectionEvents.push({
                    id: null,
                    category: "future",
                    createdBy: null,
                    scheduledFor: day.utc(true).unix(),
                    supervisorStatus: null,
                    selfStatus: null,
                    forUser: scheduledInspection.user as UserData
                })
            }
        }
    }

    return (
        <div>

            {(scheduleInspectionsLoading || inspectionsLoading || usersLoading || data == null) && (
                <div>
                    <LinearProgress />
                </div>
            )}

            {data != null && (
                <>

                    <InspectionActions refetch={refetch} usersData={usersData} currentView="calendar" />

                    <div className="inspections-calendar-wrapper">
                        <Calendar
                            popup
                            date={activeDate}
                            localizer={localizer}
                            events={inspectionEvents}
                            components={{
                                toolbar: Toolbar,
                                eventWrapper: InspectionEvent
                            }}
                            titleAccessor={(e: InspectionData) => e.forUser?.name}
                            allDayAccessor={(e: InspectionData) => true}
                            startAccessor={date}
                            endAccessor={date}
                            onNavigate={(newDate: Date, view: View, action: NavigateAction) => navigate(newDate)}
                        />
                    </div>

                </>
            )}
        </div>
    )
}

function InspectionEvent({ event }: EventWrapperProps<InspectionData>) {
    const classes = `inspection-calendar-item ${getInspectionClasses(event)}`

    
    const renderLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        (event.id == null) ? (
            <div ref={ref} className="" {...itemProps as any} />
        ) : (
            <Link to={`/inspections/${encodeURIComponent(event.id)}`} ref={ref} className="" {...itemProps} />
        )
    ))

    return (
        <Paper className={classes} component={renderLink}>
            <div>
                {event.forUser?.name}
            </div>
        </Paper>
    )
}

function Toolbar({ date, onNavigate }: ToolbarProps) {

    return (
        <div>
            <ButtonGroup color="primary">
                <Button onClick={() => onNavigate("TODAY")}>Today</Button>
                <Button onClick={() => onNavigate("PREV")}>Back</Button>
                <Button onClick={() => onNavigate("NEXT")}>Next</Button>
            </ButtonGroup>
            <span style={{ paddingLeft: "5em" }}>
                {moment(date).format("MMMM YYYY")}
            </span>
        </div>
    )
}