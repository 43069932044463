import React from 'react'
import './ScheduleTable.css'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Fab } from '@material-ui/core'
import { RecurringInspectionData } from './Schedule'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

export default function ScheduleTable({ recurringInspections, openAdd, removeRecurringInspection }: {
    recurringInspections: RecurringInspectionData[],
    removeRecurringInspection: (id: string) => void
    openAdd: () => void
}) {

    return (
        <TableContainer component={Paper}>
            <Table className="users-table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Fab color="primary" className="schedule-add-button" onClick={() => openAdd()}>
                                <AddIcon />
                            </Fab>
                        </TableCell>
                        {/*<TableCell padding="checkbox" />*/}
                        <TableCell align="left" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recurringInspections.sort((a, b) => (a.user?.name < b.user?.name) ? -1 : (a.user?.name > b.user?.name) ? 1 : 0).map((recurringInspection) => (
                        <TableRow key={recurringInspection.id}>
                            <TableCell>
                                <IconButton onClick={() => removeRecurringInspection(recurringInspection.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                            {/*<TableCell>
                                <IconButton onClick={() => { }}>
                                    <SwapHorizIcon />
                                </IconButton>
                            </TableCell>*/}
                            <TableCell component="th" scope="row" align="left">
                                {recurringInspection.user?.name}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}