import React, { useState } from "react"
import NewInspectionDialog, { NewInspectionData } from "./NewInspectionDialog"
import { Tooltip, IconButton, Button } from "@material-ui/core"
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { gql } from "apollo-boost"
import { useMutation } from "@apollo/react-hooks"
import moment from "moment"
import { GetUsersData } from "../users/Users";
import { useHistory } from "react-router-dom";
import { ScopeLocked } from "../../components/ScopeLocked";

export const NEW_INSPECTION = gql`
mutation CreateInspection($user: ID!, $date: Long!, $category: String!){
    createInspection(forUser: $user, scheduledFor: $date, category: $category) {
        status
    }
}
`

export default function InspectionActions({ refetch, usersData, currentView }: { refetch: () => void, usersData: GetUsersData | undefined, currentView: "calendar" | "list" }) {

    const [addInspection] = useMutation(NEW_INSPECTION)
    const [addDialogData, setAddDialogData] = useState<NewInspectionData>({
        open: false,
        user: "none",
        forDate: moment()
    })

    const newInspection = () => {
        if (addDialogData.user === "none") return
        addInspection({
            variables: { user: addDialogData.user, date: addDialogData.forDate.utc(true).unix(), category: "Manual" },
            update: () => {
                refetch()
            }
        })
        setAddDialogData({
            open: false,
            user: addDialogData.user,
            forDate: addDialogData.forDate
        })
    }

    const openNewInspection = () => {
        if (usersData === null) return
        setAddDialogData({
            open: true,
            user: "none",
            forDate: moment()
        })
    }

    const closeNewInspection = () => {
        let newData = Object.assign({}, addDialogData);
        newData.open = false
        setAddDialogData(newData)
    }

    let newView = ""
    if (currentView === "calendar") {
        newView = "List"
    }
    if (currentView === "list") {
        newView = "Calendar"
    }

    const history = useHistory()

    const changeView = () => {
        if (currentView === "calendar") {
            history.push("/inspections/list")
        }
        if (currentView === "list") {
            history.push("/inspections/calendar")
        }
    }

    return (
        <>
            <NewInspectionDialog handleClose={closeNewInspection} data={addDialogData} newInspection={newInspection} setData={setAddDialogData} users={usersData?.users ?? []} />

            <div className="inspection-list-actions">
                <Tooltip title="Refresh">
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <ScopeLocked requiredScopes={["truckinspect.schedule-inspections"]}>
                    <Tooltip title="New Inspection">
                        <IconButton onClick={() => openNewInspection()}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </ScopeLocked>
                <Button variant="outlined" color="primary" onClick={changeView}>
                    {newView} view
                </Button>

            </div>
        </>
    )
}