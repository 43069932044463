import React from 'react'
import './Users.css'
import { LinearProgress, Button } from '@material-ui/core';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import UsersTable from './UsersTable';

export const GET_USERS = gql`
query {
    users {
        id
      name
    }
  }  
`

export const GET_ME = gql`
query {
    me {
      id
    }
  }  
`

export interface UserData {
    id: string,
    name: string,
}

export interface GetUsersData {
    users: UserData[]
}

export default function Users() {

    const { loading: usersLoading, data: usersData } = useQuery<GetUsersData>(GET_USERS)

    if (usersLoading) {
        return (
            <div>
                <LinearProgress />
            </div>
        )
    }

    return (
        <div>
            <Button style={{ marginBottom: "10px" }} variant="contained" component="a" href="https://sso.a-qualitypools.net/users" target="_blank">Edit on A-Quality Account System</Button>
            <UsersTable
                users={usersData!!.users}
            />
        </div>
    )
}