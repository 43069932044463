import React, { useState, useEffect } from 'react'
import { AppBar, Tab, Backdrop, CircularProgress, LinearProgress, Tabs, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ScheduleTable from './ScheduleTable';
import AddDialog from './AddDialog';
import { GET_USERS, GetUsersData } from '../users/Users';

export interface RecurringInspectionData {
    id: string,
    scheduledDay: string,
    user: {
        id: string,
        name: string
    }
}

export const GET_RECURRING_INSPECTIONS = gql`
query {
    recurringInspections {
        id
        scheduledDay
        user {
            id
            name
        }
    }
}
`

export const ADD_RECURRING_INSPECTION = gql`
mutation CreateRecurringInspection($forUser: ID!, $day: Day!) {
    createRecurringInspection(forUser: $forUser, day: $day) {
        status
        recurringInspection {
            id
            scheduledDay
            user {
                id
            }
          }
    }
}
`

export const DELETE_RECURRING_INSPECTION = gql`
mutation DeleteRecurringInspection($id: ID!) {
    deleteRecurringInspection(id: $id) {
        status
    }
}
`

export default function Schedule() {

    const [week, setWeek] = useState(1)
    const [day, setDay] = useState("Monday")
    const [editAdd, setEditAdd] = useState({
        open: false,
        userId: "none"

    })
    const { loading: inspectionsLoading, data: inspectionsData, startPolling: inspectionsStartPolling, stopPolling: inspectionsStopPolling } = useQuery<{ recurringInspections: RecurringInspectionData[] }>(GET_RECURRING_INSPECTIONS)
    const { loading: usersLoading, data: usersData, startPolling: usersStartPolling, stopPolling: usersStopPolling } = useQuery<GetUsersData>(GET_USERS)
    useEffect(() => {
        inspectionsStartPolling(1000)
        usersStartPolling(1000)
        return () => {
            inspectionsStopPolling()
            usersStopPolling()
        }
    });
    const [mutateAddRecurringInspection, { loading: addLoading }] = useMutation(ADD_RECURRING_INSPECTION,
        {
            update(cache, result) {
                const { recurringInspections } = cache.readQuery<{ recurringInspections: RecurringInspectionData[] }>({ query: GET_RECURRING_INSPECTIONS })!!
                cache.writeQuery({
                    query: GET_RECURRING_INSPECTIONS,
                    data: { recurringInspections: recurringInspections.concat([result?.data?.createRecurringInspection?.recurringInspection!!]) },
                })
            }
        })
    const [mutateRemoveRecurringInspection] = useMutation(DELETE_RECURRING_INSPECTION)

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

    const addRecurringInspection = () => {
        if (editAdd.userId === "none") return
        mutateAddRecurringInspection({
            variables: {
                forUser: editAdd.userId,
                day: day.toUpperCase() + week.toString()
            }
        })
        setEditAdd({
            open: false,
            userId: ""
        })
    }

    const removeRecurringInspection = (id: string) => {
        mutateRemoveRecurringInspection({
            variables: { id: id },
            update(cache, result) {
                if (result.data?.deleteRecurringInspection?.status === "SUCCESSFUL") {
                    const { recurringInspections } = cache.readQuery<{ recurringInspections: RecurringInspectionData[] }>({ query: GET_RECURRING_INSPECTIONS })!!
                    cache.writeQuery({
                        query: GET_RECURRING_INSPECTIONS,
                        data: { recurringInspections: recurringInspections.filter((ri) => ri.id !== id) },
                    })
                }

            },
            optimisticResponse: {
                deleteRecurringInspection: {
                    status: "SUCCESSFUL",
                    __typename: "DeleteRecurringInspectionPayload"
                }
            }
        })
    }

    const openAdd = () => {
        setEditAdd({
            open: true,
            userId: "none"
        })
    }


    if (inspectionsLoading || usersLoading) {
        return (
            <div>
                <LinearProgress />
            </div>
        )
    }

    return (
        <div>

            <div className="loading-wrapper">
                <Backdrop open={addLoading} className="schedule-backdrop">
                    <CircularProgress />
                </Backdrop>
            </div>

            <FormControl variant="outlined">
                <InputLabel>Week</InputLabel>
                <Select
                    value={week}
                    onChange={(e) => setWeek(e.target.value as number)}
                    label="Week"
                >
                    <MenuItem value={1}>Week 1</MenuItem>
                    <MenuItem value={2}>Week 2</MenuItem>
                </Select>
            </FormControl>


            <AppBar position="static" color="transparent">
                <Tabs value={day} onChange={(_, v) => setDay(v)} variant="scrollable" scrollButtons="auto">
                    {days.map((day) => (
                        <Tab label={day} value={day} key={day} />
                    ))}
                </Tabs>
            </AppBar>

            <ScheduleTable
                removeRecurringInspection={removeRecurringInspection}
                recurringInspections={inspectionsData!!.recurringInspections.filter((recurringInspection) => recurringInspection.scheduledDay === (day.toUpperCase() + week.toString()))}
                openAdd={openAdd}
            />

            <AddDialog add={addRecurringInspection} users={usersData!!.users} day={day} week={week} editAdd={editAdd} setEditAdd={setEditAdd} />

        </div>
    )
}