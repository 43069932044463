import React, { useState, useEffect } from 'react'
import './NewInspectionDialog.css'
import './InspectionsList.css'
import { List, ListSubheader, ListItem, LinearProgress, Paper, Fab, FormControlLabel, Switch } from '@material-ui/core'
import { gql } from 'apollo-boost'
import { UserData, GetUsersData, GET_USERS } from '../users/Users'
import { useQuery } from '@apollo/react-hooks'
import { Link, LinkProps } from 'react-router-dom'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InspectionActions from './InspectionActions'
import { getInspectionClasses } from './Inspection'

export const GET_INSPECTIONS_LIST = gql`
query GetInspections($count: Int!, $cursor: String, $after: Long, $before: Long, $filter: InspectionStatusFilter) {
    inspections(count: $count, after: $cursor, afterTimestamp: $after, beforeTimestamp: $before, statusFilter: $filter) {
        edges {
            cursor
            node {
                id
                category
                createdBy {
                    id
                    name
                    type
                }
                scheduledFor
                supervisorStatus {
                    answers {
                        questionText
                        answerText
                        comments
                    }
                    submittedAt
                    userAnswered {
                        id
                        name
                        type
                    }
                }
                selfStatus {
                    answers {
                        questionText
                        answerText
                        comments
                    }
                    submittedAt
                    userAnswered {
                        id
                        name
                        type
                    }
                }
                forUser {
                    id
                    name
                    type
                }
            }
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
    }
}
`

export interface InspectionsGraph {
    inspections: {
        edges: InspectionEdge[]
        pageInfo: {
            startCursor: string,
            endCursor: string,
            hasNextPage: boolean,
            hasPreviousPage: boolean
        }
    }
}

export interface InspectionEdge {
    cursor: string,
    node: InspectionData
}

export interface InspectionData {
    id: string | null,
    category: string,
    createdBy: UserData | null,
    scheduledFor: number,
    supervisorStatus: InspectionStatus | null,
    selfStatus: InspectionStatus | null,
    forUser: UserData
}

export interface InspectionStatus {
    answers: Answer[],
    odometer: number | null,
    submittedAt: number,
    userAnswered: UserData
}

export interface Answer {
    questionText: string,
    answerText: string,
    comments: string
}

export default function InspectionsList() {

    const [usingDate, setUsingDate] = useState(false)
    const [selectedDate, setDate] = useState(moment())

    const fetchVars: any = { count: 10 }
    if (usingDate) { fetchVars["before"] = selectedDate.unix() }
    const { loading: inspectionsLoading, data, refetch, fetchMore } = useQuery<InspectionsGraph>(GET_INSPECTIONS_LIST, {
        variables: fetchVars,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    })

    const { loading: usersLoading, data: usersData, startPolling: usersStartPolling, stopPolling: usersStopPolling } = useQuery<GetUsersData>(GET_USERS)
    useEffect(() => {
        usersStartPolling(5000)
        return usersStopPolling
    })

    const getMore = () => {
        fetchMore({
            variables: {
                cursor: data!!.inspections.pageInfo.endCursor
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult!!.inspections.edges;
                const pageInfo = fetchMoreResult!!.inspections.pageInfo;

                return newEdges.length
                    ? {
                        inspections: {
                            __typename: (previousQueryResult.inspections as any).__typename,
                            edges: [...previousQueryResult.inspections.edges, ...newEdges],
                            pageInfo
                        }
                    }
                    : previousQueryResult;
            }
        })
    }

    const organizedInspections: any = {}

    if (data != null) {
        for (let { node: inspection } of data!!.inspections.edges) {
            const date = new Date(inspection.scheduledFor * 1000)
            const day = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
            if (!(day.getTime() in organizedInspections)) {
                organizedInspections[day.getTime()] = []
            }
            organizedInspections[day.getTime()].push(inspection)
        }
    }

    return (
        <div>

            {(inspectionsLoading || usersLoading || data == null) && (
                <div>
                    <LinearProgress />
                </div>
            )}

            {data != null && (
                <>

                    <InspectionActions refetch={refetch} usersData={usersData} currentView="list" />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={usingDate}
                                onChange={() => setUsingDate(!usingDate)}
                                color="secondary"
                            />
                        }
                        label="Filter by Date"
                    />
                    {usingDate && (
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="On or before"
                                value={selectedDate}
                                onChange={(value: any) => setDate(value)}
                            />
                        </MuiPickersUtilsProvider>
                    )}

                    <List className="inspections-list" subheader={<li />}>
                        {Object.keys(organizedInspections).length === 0 &&
                            (
                                <ListSubheader className="inspection-list-section-header">No inspections</ListSubheader>
                            )}
                        {Object.keys(organizedInspections).sort((a: any, b: any) => b - a).map((day) => {
                            const d = new Date(parseInt(day))
                            const ye = new Intl.DateTimeFormat('en', { year: 'numeric', timeZone: 'UTC' }).format(d)
                            const mo = new Intl.DateTimeFormat('en', { month: 'long', timeZone: 'UTC' }).format(d)
                            const da = new Intl.DateTimeFormat('en', { day: 'numeric', timeZone: 'UTC' }).format(d)
                            const wkda = new Intl.DateTimeFormat('en', { weekday: 'long', timeZone: 'UTC' }).format(d)
                            let dayString = `${wkda}, ${mo} ${da}, ${ye}`
                            const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                            if (d.getTime() === today.getTime()) { dayString = "Today" }
                            return (
                                <li key={day}>
                                    <ul className="inspection-list-section">
                                        <ListSubheader className="inspection-list-section-header">{dayString}</ListSubheader>
                                        {organizedInspections[day].map((inspection: InspectionData) => {
                                            const completed = inspection.selfStatus != null && inspection.supervisorStatus != null
                                             const classes = `inspection-list-item ${getInspectionClasses(inspection)}`
                                            const to = `/inspections/${encodeURIComponent(inspection.id!!)}`
                                            const renderLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
                                                <Link to={to} ref={ref} className="" {...itemProps} />
                                            ))
                                            return (
                                                <ListItem key={inspection.id!!}>
                                                    <Paper className={classes} component={renderLink}>
                                                        <div>
                                                            {inspection.forUser?.name}
                                                        </div>
                                                        <div>
                                                            {completed ? "COMPLETED" : "INCOMPLETE"}
                                                        </div>
                                                    </Paper>
                                                </ListItem>
                                            )
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                        {data?.inspections.pageInfo.hasNextPage && (
                            <li className="inspections-list-fetchmore">
                                <Fab variant="extended" onClick={getMore}>
                                    <ExpandMoreIcon />
                                Load More
                            </Fab>
                            </li>
                        )}
                    </List>
                </>
            )}
        </div>
    )
}