import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import { InspectionStatusType } from './Inspection'
import { InspectionStatus, Answer } from './InspectionsList'

interface InspectionStatusProps {
    type: InspectionStatusType,
    inspectionStatus: InspectionStatus | null
}

export function InspectionStatusHeader({ type, inspectionStatus }: InspectionStatusProps) {

    let typeName

    if (type === InspectionStatusType.self) {
        typeName = "Self Inspection"
    }
    if (type === InspectionStatusType.supervisor) {
        typeName = "Supervisor Inspection"
    }

    return (
        <div className="inspection-status-section">
            <Typography variant="h5">{typeName}</Typography>
            {inspectionStatus != null ?
                (
                    <>
                        <Typography variant="overline">Submitted by {inspectionStatus.userAnswered?.name} at {new Date(inspectionStatus.submittedAt * 1000).toLocaleString()}</Typography>
                        <br />
                        {inspectionStatus.odometer && <Typography variant="overline">Odometer: {inspectionStatus.odometer}</Typography>}
                    </>
                ) : (
                    <>
                        <Typography variant="overline" className="inspection-label-nys">Not yet submitted</Typography>
                        <br />
                    </>
                )}
        </div>
    )
}

export function InspectionStatusAnswer({ answer }: { answer: Answer | undefined }) {

    if (answer == null) {
        return (
            <div className="inspection-status-section" />
        )
    }

    let answerTextClass = ""
    if (answer.answerText === "yes") {
        answerTextClass += "inspection-answer-good"
    }
    if (answer.answerText === "no") {
        answerTextClass += "inspection-answer-bad"
    }

    return (
        <div className="inspection-status-section">
            <Paper className="inspection-status-answer">
                <Typography variant="h6">{answer.questionText}</Typography>
                <Typography variant="h6" className={answerTextClass}>{answer.answerText}</Typography>
                {answer.comments !== "" &&
                    (
                        <Typography color="primary">Comments: {answer.comments}</Typography>
                    )}
            </Paper>
        </div>
    )
}