import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import InspectionsList from './InspectionsList'
import Inspection from './Inspection'
import InspectionsCalendar from './InspectionsCalendar'

export default function InspectionsDispatcher() {
    return (
        <Switch>
            <Route path="/inspections/list">
                <InspectionsList />
            </Route>
            <Route path="/inspections/calendar">
                <InspectionsCalendar />
            </Route>
            <Route path="/inspections/:id" render={(routeProps) => (
                <Inspection id={decodeURIComponent(routeProps.match.params.id)} />
            )} />
            <Route path="/inspections" exact>
                <Redirect to="/inspections/calendar" />
            </Route>
        </Switch>
    )
}