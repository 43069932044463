import React from 'react';
import './App.css';
import Entrypoint from './Entrypoint'
import Login from './login/Login';
import CssBaseline from '@material-ui/core/CssBaseline';
import ApolloClient, { InMemoryCache, defaultDataIdFromObject, gql } from 'apollo-boost';
import { toIdValue } from 'apollo-utilities';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider, Snackbar, StylesProvider } from '@material-ui/core';
import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';

const LOGOUT = gql`
mutation Logout {
  logout
}
`

class App extends React.Component<{}, { token: string, scope: string[], networkError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = {
      token: window.localStorage.getItem('token') ?? '',
      scope: window.localStorage.getItem('scope')?.split(' ') ?? [],
      networkError: false
    }
  }

  setToken = (token: string, scope: string[]) => {
    this.setState({
      token: token,
      scope: scope
    })
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('scope', scope.join(' '))
  }

  hasToken = () => this.state.token !== ''


  client = new ApolloClient({
    uri: process.env.NODE_ENV === "production" ? `https://inspection.a-qualitypools.net/graphql` : "http://localhost:8080/graphql",
    request: (operation) => {
      operation.setContext({
        headers: {
          authorization: this.hasToken() ? `User ${this.state.token}` : ''
        }
      })
    },
    cache: new InMemoryCache({
      dataIdFromObject: (object) => ("id" in object) ? object.id : defaultDataIdFromObject(object),
      cacheRedirects: {
        Query: {
          node: (_, args) => {
            return toIdValue(args.id)
          }
        }
      }
    }),
    onError: ({ graphQLErrors, response, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message.includes("not authorized")) {
            this.logout()
            if (response) { response.errors = undefined }
          }
        })
      }
      if (networkError) {
        this.setState({ networkError: true })
        if (response) { response.errors = undefined }
      }
    }
  })

  logout = () => {
    this.client.mutate({
      mutation: LOGOUT
    })
    this.client.clearStore()
    this.setToken('', [])
    window.localStorage.clear()
  }

  render() {
    const Base = (props: any) => (
      <ApolloProvider client={this.client}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              {props.children}
            </Router>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.networkError}
              autoHideDuration={6000}
              onClose={() => this.setState({ networkError: false })}
              message="A network error ocurred. Please try again later"
            />
          </ThemeProvider>
        </StylesProvider>
        <CssBaseline />

      </ApolloProvider>
    )

    if (!this.hasToken()) {
      return (
        <Base>
          <Login setToken={this.setToken} />
        </Base>
      )
    }

    return (
      <Base>
        <Entrypoint logout={this.logout} />
      </Base>
    )
  }

}

export default App;
