import React, { useState, useEffect } from 'react'
import './Inspection.css'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { InspectionData, Answer } from './InspectionsList'
import { LinearProgress, Typography, Hidden, AppBar, Tabs, Tab, Divider, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { InspectionStatusHeader, InspectionStatusAnswer } from './InspectionStatus'
import { useHistory } from 'react-router-dom'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment';
import { ScopeLocked } from '../../components/ScopeLocked'

export const GET_INSPECTION = gql`
query GetInspection($id: ID!) {
    node(id: $id) {
        ... on Inspection {
            id
            category
            createdBy {
                id
                name
                type
            }
            scheduledFor
            supervisorStatus {
                odometer
                answers {
                    questionText
                    answerText
                    comments
                }
                submittedAt
                userAnswered {
                    id
                    name
                    type
                }
            }
            selfStatus {
                answers {
                    questionText
                    answerText
                    comments
                }
                submittedAt
                userAnswered {
                    id
                    name
                    type
                }
            }
            forUser {
                id
                name
                type
            }
        }
    }
}
`

export const DELETE_INSPECTION = gql`
mutation DeleteInspection($id: ID!){
    deleteInspection(id: $id) {
        status
    }
}
`

export const UPDATE_INSPECTION = gql`
mutation UpdateInspection($id: ID!, $scheduledFor: Long!){
    updateInspection(id: $id, scheduledFor: $scheduledFor) {
        status
    }
}
`

export enum InspectionStatusType {
    self = "self",
    supervisor = "supervisor"
}

export default function Inspection({ id }: { id: string }) {
    const { loading, data, startPolling, stopPolling } = useQuery<{ node: InspectionData }>(GET_INSPECTION, {
        variables: { id: id }
    })


    const [deleteInspection, { loading: deleteLoading }] = useMutation(DELETE_INSPECTION, {
        variables: { id: id }
    })

    const [updateInspection, { loading: updateLoading }] = useMutation(UPDATE_INSPECTION)

    useEffect(() => {
        startPolling(1000)
        return stopPolling
    });

    const [showingAll, setShowingAll] = useState(false)

    if (loading || deleteLoading || updateLoading) {
        return (
            <div>
                <LinearProgress />
            </div>
        )
    }

    if (data?.node == null) {
        return (
            <div>
                Invalid inspection
            </div>
        )
    }

    const inspection = data!!.node

    const d = new Date(inspection.scheduledFor * 1000)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric', timeZone: 'UTC' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'long', timeZone: 'UTC' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: 'numeric', timeZone: 'UTC' }).format(d)
    const wkda = new Intl.DateTimeFormat('en', { weekday: 'long', timeZone: 'UTC' }).format(d)
    let dayString = `${wkda}, ${mo} ${da}, ${ye}`

    return (
        <div className="inspection-wrapper">
            <div className="inspection-heading">
                <Typography variant="h2">Inspection for {inspection.forUser?.name} ({inspection.category})</Typography>
                <Typography variant="overline">Scheduled for {dayString}</Typography>
                {inspection.createdBy != null && (
                    <Typography variant="overline">Created by {inspection.createdBy!!.name}</Typography>
                )}
                <ScopeLocked requiredScopes={["truckinspect.delete-inspections"]}>
                    <DeleteInspectionButton inspection={inspection} deleteInspection={deleteInspection} />
                </ScopeLocked>
                <ScopeLocked requiredScopes={["truckinspect.delete-inspections", "truckinspect.schedule-inspections"]}>
                    <RescheduleInspectionButton inspection={inspection} updateInspection={updateInspection} />
                </ScopeLocked>
                <div className="inspection-filter-container">
                    <Button variant="outlined" onClick={() => setShowingAll(!showingAll)}>{showingAll ? "Show summary" : "Show all"}</Button>
                </div>
            </div>
            <Hidden smDown>
                <SideBySideInspectionStatuses inspection={inspection} showingAll={showingAll} />
            </Hidden>
            <Hidden mdUp>
                <TabbedInspectionStatuses inspection={inspection} showingAll={showingAll} />
            </Hidden>
        </div>
    )
}

function SideBySideInspectionStatuses({ inspection, showingAll }: { inspection: InspectionData, showingAll: boolean }) {
    return (
        <div className="inspection-side-by-side-wrapper">
            <div className="inspection-side-by-side">
                <InspectionStatusHeader type={InspectionStatusType.self} inspectionStatus={inspection.selfStatus} />
                <Divider orientation="vertical" flexItem className="inspection-side-divider" />
                <InspectionStatusHeader type={InspectionStatusType.supervisor} inspectionStatus={inspection.supervisorStatus} />
            </div>
            {Object.values(organizeAnswers(inspection, showingAll)).map((v) => (
                <div className="inspection-side-by-side">
                    <InspectionStatusAnswer answer={v.self} />
                    <Divider orientation="vertical" flexItem className="inspection-side-divider" />
                    <InspectionStatusAnswer answer={v.supervisor} />
                </div>
            ))}
        </div>
    )
}

function TabbedInspectionStatuses({ inspection, showingAll }: { inspection: InspectionData, showingAll: boolean }) {

    const [tab, setTab] = useState<InspectionStatusType>(InspectionStatusType.self)

    let inspectionStatus = null
    if (tab === InspectionStatusType.self) {
        inspectionStatus = inspection.selfStatus
    }
    if (tab === InspectionStatusType.supervisor) {
        inspectionStatus = inspection.supervisorStatus
    }

    return (
        <div className="inspection-tabbed">
            <AppBar position="sticky">
                <Tabs value={tab} onChange={(_, v) => setTab(v)} centered>
                    <Tab value={InspectionStatusType.self} label="Self Inspection" wrapped />
                    <Tab value={InspectionStatusType.supervisor} label="Supervisor Inspection" wrapped />
                </Tabs>
            </AppBar>
            <InspectionStatusHeader type={tab} inspectionStatus={inspectionStatus} />
            {Object.values(organizeAnswers(inspection, showingAll)).map((v) => (
                <InspectionStatusAnswer answer={v[tab]} />
            ))}
        </div>
    )
}

function DeleteInspectionButton({ inspection, deleteInspection }: {
    inspection: InspectionData,
    deleteInspection: (arg0: any) => void
}) {

    const [deleteOpen, setDeleteOpen] = useState(false)
    const history = useHistory()

    const delInspection = () => {
        deleteInspection({
            update: () => {
                history.push("/inspections")
            }
        })
    }

    return (
        <>
            <Button variant="outlined" color="secondary" onClick={() => setDeleteOpen(true)}>Delete</Button>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                <DialogTitle>Delete this inspection?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={delInspection} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function RescheduleInspectionButton({ inspection, updateInspection }: {
    inspection: InspectionData,
    updateInspection: (arg0: any) => void
}) {

    const [rescheduleOpen, setRescheduleOpen] = useState(false)
    const [resDate, setResDate] = useState(moment())

    const resInspection = () => {
        updateInspection({
            variables: { id: inspection.id, scheduledFor: resDate.utc(true).unix() }
        })
        setRescheduleOpen(false)
    }

    return (
        <>
            <Button variant="outlined" color="secondary" onClick={() => setRescheduleOpen(true)}>Reschedule Inspection</Button>

            <Dialog open={rescheduleOpen} onClose={() => setRescheduleOpen(false)}>
                <DialogTitle>Reschedule Inspection</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="Date"
                                value={resDate}
                                onChange={(value: any) => setResDate(value)}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRescheduleOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={resInspection} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

interface OrganizedAnswers {
    [question: string]: {
        self: Answer | undefined,
        supervisor: Answer | undefined
    }
}

function organizeAnswers(inspection: InspectionData, showingAll: boolean): OrganizedAnswers {
    let organized: OrganizedAnswers = {}

    if (inspection.selfStatus != null) {
        for (const answer of inspection.selfStatus!!.answers) {
            if (!(answer.questionText in organized)) {
                organized[answer.questionText] = {
                    self: undefined,
                    supervisor: undefined
                }
            }
            organized[answer.questionText].self = answer
        }
    }
    if (inspection.supervisorStatus != null) {
        for (const answer of inspection.supervisorStatus!!.answers) {
            if (!(answer.questionText in organized)) {
                organized[answer.questionText] = {
                    self: undefined,
                    supervisor: undefined
                }
            }
            organized[answer.questionText].supervisor = answer
        }
    }

    let keep: OrganizedAnswers = {}

    for (let key of Object.keys(organized)) {
        if (showingAll ||
            organized[key].self?.answerText.toLowerCase() !== "yes" ||
            organized[key].self?.comments !== "" ||
            organized[key].supervisor?.answerText.toLowerCase() !== "yes" ||
            organized[key].supervisor?.comments !== "") {
            keep[key] = organized[key]
        }
    }

    return keep
}

export function getInspectionClasses(inspection: InspectionData): string {
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    const date = new Date(inspection.scheduledFor * 1000)
    const d = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    let classes = ""
    if (inspection.id == null) {
        classes += " inspection-future"
    } else if (inspection.selfStatus != null && inspection.supervisorStatus != null) {
        classes += " inspection-completed"
    } else {
        if (inspection.selfStatus != null) {
            if (d.getTime() >= today.getTime()) {
                classes += " inspection-halfcomplete-ok"
            } else {
                classes += " inspection-halfcomplete"
            }
        } else {
            if (d.getTime() >= today.getTime()) {
                classes += " inspection-incomplete-ok"
            } else {
                classes += " inspection-incomplete"
            }
        }
    }
    return classes
}