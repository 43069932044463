import React from 'react'
import './User.css'
import { LinearProgress, Paper, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { QuestionData } from '../questions/Question';


export interface UserData {
  id: string,
  name: string,
}

export const GET_USER = gql`
query GetUser($id: String!) {
  user(id: $id) {
    id
    name
  }
}
`

export const GET_USER_QUESTIONS = gql`
query GetQuestions($id: ID!) {
  questions(forUser: $id) {
    questionText
    lastCompleted
  }
}
`

export default function User({ id }: { id: string }) {

  const { loading: userLoading, data: userData } = useQuery<{ user: UserData } | null>(GET_USER, { variables: { id } })
  const { loading: questionsLoading, data: questionsData } = useQuery<{ questions: QuestionData[] } | null>(GET_USER_QUESTIONS, { variables: { id } })

  if (userLoading || questionsLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    )
  }

  return (
    <div>
      <h1>{userData?.user.name}</h1>
      {questionsData?.questions.map(q => (
        <Paper className="user-question">
          <Typography variant="h6">{q.questionText}</Typography>
          <Typography>Last Completed: {q.lastCompleted == null ? "Never" : (() => {
            const d = new Date(q.lastCompleted * 1000)
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric', timeZone: 'UTC' }).format(d)
            const mo = new Intl.DateTimeFormat('en', { month: 'long', timeZone: 'UTC' }).format(d)
            const da = new Intl.DateTimeFormat('en', { day: 'numeric', timeZone: 'UTC' }).format(d)
            const wkda = new Intl.DateTimeFormat('en', { weekday: 'long', timeZone: 'UTC' }).format(d)
            return `${wkda}, ${mo} ${da}, ${ye}`
          })()}</Typography>
        </Paper>
      ))}
    </div>
  )
}