import React from 'react'
import TopBar from './appbar/TopBar';
import { Switch, Route, Redirect } from 'react-router-dom';
import Questions from './routes/questions/Questions';
import Users from './routes/users/Users';
import Schedule from './routes/schedule/Schedule';
import InspectionsDispatcher from './routes/inspections/InspectionsDispatcher';
import { Typography } from '@material-ui/core';
import User from './routes/user/User';

export default function Entrypoint(props: { logout: () => void }) {
    return (
        <div>
            <TopBar logout={props.logout}>
                <Switch>
                    <Route path="/dashboard">
                        <Typography variant="h3" align="center">Select a tab on the left</Typography>
                    </Route>
                    <Route path="/inspections">
                        <InspectionsDispatcher />
                    </Route>
                    <Route path="/questions">
                        <Questions />
                    </Route>
                    <Route path="/users">
                        <Users />
                    </Route>
                    <Route path="/user/:id" render={(routeProps) => (
                        <User id={routeProps.match.params.id} />
                    )} />
                    <Route path="/schedule">
                        <Schedule />
                    </Route>
                    <Route>
                        <Redirect to="/dashboard" />
                    </Route>
                </Switch>
            </TopBar>
        </div>
    )
}