import React from 'react'
import { useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';
import { UserData } from '../users/Users';

export interface NewInspectionData {
    open: boolean,
    user: string,
    forDate: Moment
}

export default function NewInspectionDialog({ handleClose, newInspection, data, setData, users }: 
    { 
        handleClose: () => void, 
        newInspection: () => void, 
        data: NewInspectionData, 
        setData: (data: NewInspectionData) => void,
        users: UserData[] 
    }) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const setUser = (value: string) => {
        setData({
            open: true,
            user: value,
            forDate: data.forDate
        })
    }

    const setDate= (value: Moment) => {
        setData({
            open: true,
            user: data.user,
            forDate: value
        })
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={data.open}
            onClose={handleClose}
        >
            <DialogTitle>New Inspection</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    NOTE: This only creates a one-off inspection. To create a recurring inspection, go to the schedule page
                </DialogContentText>
                <FormControl variant="outlined">
                    <InputLabel className="select-label">User</InputLabel>
                    <Select
                        value={data.user}
                        onChange={(e) => setUser(e.target.value as string)}
                        label="User"
                    >
                        <MenuItem value="none" disabled>
                            None
                        </MenuItem>
                        {users.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).map((user) => (
                            <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        value={data.forDate}
                        onChange={(value: any) => setDate(value)}
                    />
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={newInspection} color="primary" autoFocus>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )

}