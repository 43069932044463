import React     from 'react'
import { TextField, Typography } from '@material-ui/core'

interface EditableProps {
    editActive: boolean,
    absoluteValue: string,
    editableValue: string,
    onChange: (value: string) => void,
    label: string,
    textfieldClassName?: string,
    textClassName?: string,
    multiline?: boolean
}

export default function Editable({ editActive, absoluteValue, editableValue, label, onChange, textfieldClassName, textClassName, multiline }: EditableProps) {
    if (textfieldClassName == null) textfieldClassName = ""
    if (textClassName == null) textClassName = ""
    if (multiline == null) multiline = false

    if (!editActive) {
        return (
            <Typography className={textClassName}>
                {absoluteValue}
            </Typography>
        )
    } else {
        return (
            <TextField className={textfieldClassName} multiline={multiline} label={label} variant="outlined" value={editableValue} onChange={(e) => onChange(e.target.value)} />
        )
    }

}