import React, { useState, useEffect } from 'react'
import './Questions.css'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks';
import Question, { QuestionData } from './Question';
import Node from '../../types/Node';
import { Fab, CircularProgress, LinearProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export const UPDATE_QUESTION = gql`
mutation UpdateQuestion($id: ID!, $text: String, $shortName: String, $critical: Boolean, $index: Int) {
    updateQuestion(id: $id, questionText: $text, shortName: $shortName, critical: $critical, index: $index) {
      status
      question {
        id
        questionText
        shortName
        critical
        index
      }
    }
  }
`


export const GET_QUESTIONS = gql`
    query {
        questions {
            id
            index
            questionText
            shortName
            critical
        }
    }
`
export const DELETE_QUESTION = gql`
    mutation DeleteQuestion($id: ID!) {
        deleteQuestion(id: $id) {
            status
            node {
                id
            }
        }
    }  
`

export const ADD_QUESTION = gql`
mutation CreateQuestion($text: String!, $shortName: String!, $critical: Boolean!, $index: Int!) {
    createQuestion(questionText: $text, shortName: $shortName, critical: $critical, index: $index, type: BOOLEAN) {
      status
      question {
        id
        questionText
        critical
        index
      }
    }
  }
`

export default function Questions() {

    interface GetQuestionsData {
        questions: QuestionData[],
        "__typename"?: string
    }

    interface DeleteQuestionData {
        status: string,
        node: Node
        "__typename"?: string
    }

    interface CreateQuestionData {
        status: string,
        question: QuestionData | null
        "__typename"?: string
    }

    const { loading, data, startPolling, stopPolling } = useQuery<GetQuestionsData>(GET_QUESTIONS)
    useEffect(() => {
        startPolling(1000)
        return stopPolling
    })
    const [deleteQuestion] = useMutation<{ deleteQuestion: DeleteQuestionData, "__typename"?: string }>(DELETE_QUESTION,
        {
            update(cache, result) {
                if (result.data?.deleteQuestion?.status === "SUCCESSFUL") {
                    const { questions } = cache.readQuery<GetQuestionsData>({ query: GET_QUESTIONS })!!;
                    cache.writeQuery({
                        query: GET_QUESTIONS,
                        data: { questions: questions.filter((question) => question.id !== result.data?.deleteQuestion?.node?.id) },
                    })
                }
            }
        })

    const [addQuestion, { loading: addLoading }] = useMutation<{ createQuestion: CreateQuestionData }>(ADD_QUESTION,
        {
            update(cache, result) {
                const { questions } = cache.readQuery<GetQuestionsData>({ query: GET_QUESTIONS })!!;
                if (result?.data?.createQuestion?.question != null) {
                    setEditingQuestion(result?.data?.createQuestion?.question!!.id)
                    if (result.data.createQuestion.question.index === 1) {
                        result.data.createQuestion.question.index = 0
                    }
                    cache.writeQuery({
                        query: GET_QUESTIONS,
                        data: { questions: questions.concat([result?.data?.createQuestion?.question!!]) },
                    });
                }
            }
        })
    const [updateQuestion] = useMutation(UPDATE_QUESTION)

    const newQuestion = (bottom: boolean) => {
        addQuestion({ variables: { text: "", shortName: "", index: bottom ? 99999 : 0, critical: true } })
    }

    const moveQuestion = (question: QuestionData, newIndex: number) => {
        updateQuestion({
            variables: { id: question.id, index: newIndex },
            optimisticResponse: {
                "updateQuestion": {
                    "status": "SUCCESSFUL",
                    "question": {
                        "id": question.id,
                        "questionText": question.questionText,
                        "shortName": question.shortName,
                        "critical": question.critical,
                        "index": newIndex,
                        "__typename": "Question"
                    },
                    "__typename": "UpdateQuestionPayload"
                }
            }
        })
    }

    const removeQuestion = (id: string) => {
        deleteQuestion({
            variables: { id: id },
            optimisticResponse: {
                "deleteQuestion": {
                    "node": {
                        "id": id,
                        "__typename": "Question"
                    },
                    "status": "SUCCESSFUL",
                    "__typename": "DeleteQuestionPayload"
                },
            }
        })
        setEditingQuestion("")
    }

    const [editingQuestion, setEditingQuestion] = useState("")

    if (loading) {
        return (
            <div>
                <LinearProgress />
            </div>
        )
    }

    return (
        <div>
            <div className="questions-top">
                <div className="questions-add-wrapper">
                    <Fab
                        color="primary"
                        onClick={() => newQuestion(false)}
                        disabled={editingQuestion !== ""}
                    >
                        <AddIcon />
                    </Fab>
                    {addLoading && <CircularProgress size={68} className="questions-add" />}
                </div>
            </div>
            {data!!.questions.sort((a, b) => a.index - b.index).map((question) => (
                <Question
                    editActive={editingQuestion === question.id}
                    setEditActive={(active: boolean) => setEditingQuestion(active ? question.id : "")}
                    moveQuestion={(newIndex: number) => moveQuestion(question, newIndex)}
                    question={question}
                    onDelete={() => removeQuestion(question.id)}
                    key={question.id}
                />
            ))}
            {data!!.questions.length > 0 && (
                <div className="questions-bottom">
                    <div className="questions-add-wrapper">
                        <Fab
                            color="primary"
                            onClick={() => newQuestion(true)}
                            disabled={editingQuestion !== ""}
                        >
                            <AddIcon />
                        </Fab>
                        {addLoading && <CircularProgress size={68} className="questions-add" />}
                    </div>
                </div>
            )}
        </div>
    )
}