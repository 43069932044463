import React from 'react'
import { ListItem } from '@material-ui/core'
import { useRouteMatch, Link } from 'react-router-dom'

export default function Tab(props: {name: string, path: string}) {


    return (
        <ListItem button selected={useRouteMatch(props.path) != null} component={Link} to={props.path}>
            {props.name}
        </ListItem>
    )
}